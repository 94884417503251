<h2 mat-dialog-title>Benachrichtigungen zulassen</h2>
<mat-dialog-content class="mat-typography">
  <p>
    Um das Beste aus unserer Plattform herauszuholen, empfehlen wir Ihnen,
    Push-Benachrichtigungen zu aktivieren. So bleiben Sie immer auf dem
    Laufenden über wichtige Updates und Nachrichten.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="handleClose()" [disabled]="isSubmitting">
    Abbrechen
  </button>
  <button
    mat-raised-button
    color="primary"
    cdkFocusInitial
    (click)="handleSubscription()"
    [disabled]="isSubmitting"
  >
    <mat-spinner
      *ngIf="isSubmitting; else submitText"
      [diameter]="25"
      [strokeWidth]="4"
    ></mat-spinner>
    <ng-template #submitText>
      <span>Erlauben</span>
    </ng-template>
  </button>
</mat-dialog-actions>
