<div class="button-content">
  <button
    mat-mini-fab
    class="notification-button"
    [matMenuTriggerFor]="menu"
    (click)="shouldAskForPermission()"
    color="primary"
    matBadgeHidden="{{ unreadCount === 0 }}"
    matBadge="{{ unreadCount }}"
    matBadgeColor="warn"
    matBadgeSize="medium"
  >
    <mat-icon aria-hidden="false" aria-label="notification-icon"
      >notifications
    </mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu" xPosition="before" class="notification-center">
  <div class="py-1 pl-3 pr-1 d-flex align-center justify-between">
    <h3 class="mb-0 fw-medium">Benachrichtigungen</h3>
    <button mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngIf="unreadNotifications.length !== 0">
    <div
      class="notification unread"
      *ngFor="let notification of unreadNotifications"
      (click)="markAsRead(notification)"
    >
      <div>
        <div>
          <mat-icon>
            {{ notificationOriginTypeToIcon(notification.origin.type) }}
          </mat-icon>
        </div>
        <div class="w-100">
          <span class="d-flex align-center justify-between">
            <h3 class="mb-0 mr-auto">{{ notification.title }}</h3>
            <small>
              {{ notification.created | date: 'dd.MM HH:mm' }}
            </small>
          </span>
          <p>{{ notification.body }}</p>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </ng-container>

  <ng-container *ngIf="notifications.length !== 0">
    <div
      class="notification"
      *ngFor="let notification of notifications"
      (click)="openNotificationUrl(notification.url)"
    >
      <div>
        <div>
          <mat-icon>
            {{ notificationOriginTypeToIcon(notification.origin.type) }}
          </mat-icon>
        </div>
        <div class="w-100">
          <span class="d-flex align-center justify-between">
            <h3 class="mb-0 mr-auto">{{ notification.title }}</h3>
            <small>
              {{ notification.created | date: 'dd.MM HH:mm' }}
            </small>
          </span>
          <p>{{ notification.body }}</p>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </ng-container>

  <div class="px-3 pt-2 text-center">
    <button
      *ngIf="!limitReached; else noNotifications"
      mat-raised-button
      color="primary"
      [disabled]="isFetching"
      (click)="fetchNotifications(); $event.stopPropagation()"
    >
      <mat-spinner *ngIf="isFetching" diameter="20"></mat-spinner>
      <span *ngIf="!isFetching">Frühere Benachrichtigungen lesen</span>
    </button>
    <ng-template #noNotifications>
      <span class="text-primary fw-medium text-center"
        >Keine weiteren Benachrichtigungen</span
      >
    </ng-template>
  </div>
</mat-menu>
<audio #notificationSound>
  <source src="/assets/sounds/notification.mp3" type="audio/mp3" />
</audio>
