import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/shared/utils/snackbar/snackbar.service';
import { NotificationService } from 'src/app/shared/services/notifications.service';
import {
  NOTIFICATION_PERMISSION_DENIED,
  NOTIFICATION_PERMISSION_GRANTED,
  NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY,
} from 'src/app/models/notification';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-notification-dialog',
  standalone: true,
  imports: [CommonModule, AppMaterialModule],
  templateUrl: './notification-dialog.component.html',
  styleUrl: './notification-dialog.component.scss',
})
export class NotificationDialogComponent {
  constructor(
    private swPush: SwPush,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<NotificationDialogComponent>
  ) {}

  isSubmitting = false;
  snackbarService = inject(SnackbarService);

  handleClose() {
    localStorage.setItem(
      NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY,
      NOTIFICATION_PERMISSION_DENIED
    );
    this.dialogRef.close();
  }

  handleSubscription() {
    this.isSubmitting = true;
    this.swPush
      .requestSubscription({
        serverPublicKey: environment.notifications.publicKey,
      })
      .then((sub) => {
        this.notificationService.subscribe(sub).subscribe({
          next: () => {
            localStorage.setItem(
              NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY,
              NOTIFICATION_PERMISSION_GRANTED
            );
            this.snackbarService.open({
              type: 'success',
              message: 'Benachrichtigungen aktiviert',
            });
            this.dialogRef.close();
          },
          error: (err) => {
            localStorage.setItem(
              NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY,
              NOTIFICATION_PERMISSION_GRANTED
            );
            console.error('Could not subscribe to notifications', err);
            this.snackbarService.open({
              type: 'error',
              message:
                'Bei der Synchronisierung der Genehmigung mit dem Server ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.',
            });
          },
        });
      })
      .catch((err) => {
        console.error('Could not subscribe to notifications', err);
        this.snackbarService.open({
          type: 'error',
          message:
            'Benachrichtigungen wurden blockiert, falls Sie sie aktivieren möchten, setzen Sie bitte Ihre Einstellungen zurück',
        });
        this.dialogRef.close();
        localStorage.setItem(
          NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY,
          NOTIFICATION_PERMISSION_DENIED
        );
      });
  }
}
